/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PublicCourseWithPriceDetailsEntity = {
    id: string;
    name: string;
    description: string;
    maximumAttendees: number | null;
    locationId: string | null;
    priceInMills: number;
    termsOfServiceId: string | null;
    revocationPolicyId: string | null;
    autoSchedulingType: PublicCourseWithPriceDetailsEntity.autoSchedulingType;
    stripePaymentProfileId: string;
    priceWithoutTaxesInMills: number;
    taxesInMills: number;
    totalPriceInMills: number;
    startsAtPriceWithTaxesInMills: number;
};
export namespace PublicCourseWithPriceDetailsEntity {
    export enum autoSchedulingType {
        OFF = 'OFF',
        REMINDER = 'REMINDER',
        COPILOT = 'COPILOT',
        AUTOMATIC = 'AUTOMATIC',
    }
}

