/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PublicCourseEntity = {
    id: string;
    name: string;
    description: string;
    maximumAttendees: number | null;
    locationId: string | null;
    priceInMills: number;
    termsOfServiceId: string | null;
    revocationPolicyId: string | null;
    autoSchedulingType: PublicCourseEntity.autoSchedulingType;
    stripePaymentProfileId: string;
};
export namespace PublicCourseEntity {
    export enum autoSchedulingType {
        OFF = 'OFF',
        REMINDER = 'REMINDER',
        COPILOT = 'COPILOT',
        AUTOMATIC = 'AUTOMATIC',
    }
}

