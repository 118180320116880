import { BookingEntity, StripePaymentProfileEntity } from '../../qourses-api-client'
import useGetCourse from '@/hooks/courses/useGetCourse.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import useGetMeeting from '@/hooks/meetings/useGetMeeting.tsx'
import { cn } from '@/shadcn/lib/utils.ts'
import { DateTime } from 'luxon'
import useGetCourseGroup from '@/hooks/courseGroups/useGetCourseGroup.tsx'
import useGetCourseGroupMeetings from '@/hooks/courseGroups/useGetCourseGroupMeetings.tsx'
import useGetMultipassProduct from '@/hooks/multipasses/useGetMultipassProduct.tsx'
import useGetOrganizationPublic from '@/hooks/public/useGetOrganizationPublic.tsx'
import useGetInstructor from '@/hooks/instructors/useGetInstructor.tsx'
import useGetPaymentProfile from '@/hooks/organization/useGetPaymentProfile.tsx'

export function CourseName({ courseId }: { courseId: string }) {
  const { course, isError, isLoading } = useGetCourse(courseId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{course.name}</>
}

export function CourseGroupName({ courseGroupId }: { courseGroupId: string }) {
  const { courseGroup, isError, isLoading } = useGetCourseGroup(courseGroupId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return <>{courseGroup.name}</>
}

export function CourseNameWithMeetingStart({ meetingId }: { meetingId: string }) {
  const { meeting, isError, isLoading } = useGetMeeting(meetingId)

  if (isLoading) {
    return (
      <div className="space-y-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-3 w-24" />
      </div>
    )
  }

  if (isError) return null

  return (
    <div className="text-sm">
      <CourseName courseId={meeting.courseId} />
      <p className="text-xs text-muted-foreground">
        {DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED)}
      </p>
    </div>
  )
}

export function BookingCourseGroupName({ booking }: { booking: BookingEntity }) {
  const { courseGroup, isError, isLoading } = useGetCourseGroup(booking.courseGroupId)
  const { meetings } = useGetCourseGroupMeetings(booking.courseGroupId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  const startAndEndDates = meetings.reduce(
    (acc, meeting) => {
      const start = DateTime.fromISO(meeting.start)
      if (acc.start === null || start < acc.start) {
        acc.start = start
      }
      if (acc.end === null || start > acc.end) {
        acc.end = start
      }
      return acc
    },
    { start: null, end: null },
  )

  return (
    <div
      className={cn(
        'text-sm text-muted-foreground',
        booking.bookingStatus === BookingEntity.bookingStatus.FULFILLED &&
          'text-gray-800',
      )}
    >
      {courseGroup.name}
      <div className="flex max-w-xs flex-wrap gap-x-1 text-xs text-muted-foreground">
        <p>Kursgruppe</p>
        <div>
          ({DateTime.fromISO(startAndEndDates.start).toLocaleString(DateTime.DATE_SHORT)})
        </div>
      </div>
    </div>
  )
}

export function MultipassProductName({
  multipassProductId,
}: {
  multipassProductId: string
}) {
  const { multipassProduct, isError, isLoading } =
    useGetMultipassProduct(multipassProductId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <div className="text-sm">
      {multipassProduct.name}
      <p className="text-xs text-muted-foreground">Mehrfachkarte</p>
    </div>
  )
}

export function OrganizationLegalNameOrInstructorLegalNameViaId({
  stripePaymentProfileId,
}: {
  stripePaymentProfileId: string
}) {
  const { paymentProfile, isError, isLoading } =
    useGetPaymentProfile(stripePaymentProfileId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <OrganizationLegalNameOrInstructorLegalName stripePaymentProfile={paymentProfile} />
  )
}

export function OrganizationLegalNameOrInstructorLegalName({
  stripePaymentProfile,
}: {
  stripePaymentProfile: StripePaymentProfileEntity
}) {
  if (stripePaymentProfile.organizationId) {
    return <OrganizationLegalName organizationId={stripePaymentProfile.organizationId} />
  }

  if (stripePaymentProfile.instructorId) {
    return <InstructorLegalName instructorId={stripePaymentProfile.instructorId} />
  }
}

export function OrganizationLegalName({ organizationId }: { organizationId: string }) {
  const { organization, isLoading } = useGetOrganizationPublic(organizationId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  return (
    <>
      {organization.companyLegalName ? organization.companyLegalName : organization.name}
    </>
  )
}

export function InstructorLegalName({ instructorId }: { instructorId: string }) {
  const { instructor, isError, isLoading } = useGetInstructor(instructorId)

  if (isLoading) {
    return <Skeleton className="h-4 w-24" />
  }

  if (isError) return null

  return (
    <>
      {instructor.legalName
        ? instructor.legalName
        : `${instructor.firstName} ${instructor.lastName}`}
    </>
  )
}
