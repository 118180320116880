/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseScheduleEntity } from '../models/CourseScheduleEntity';
import type { MeetingEntity } from '../models/MeetingEntity';
import type { MeetingScheduleEntity } from '../models/MeetingScheduleEntity';
import type { PostCourseScheduleDto } from '../models/PostCourseScheduleDto';
import type { PostMeetingScheduleDto } from '../models/PostMeetingScheduleDto';
import type { PostScheduleBatchDto } from '../models/PostScheduleBatchDto';
import type { ScheduleBatchEntity } from '../models/ScheduleBatchEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SchedulingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Course Schedules
     * @returns CourseScheduleEntity Course Schedules have been returned
     * @throws ApiError
     */
    public schedulingControllerGetCourseSchedules(): CancelablePromise<Array<CourseScheduleEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/courseSchedule',
        });
    }
    /**
     * Create a Course Schedule
     * @param requestBody
     * @returns CourseScheduleEntity Course Schedule has been created
     * @throws ApiError
     */
    public schedulingControllerCreateCourseSchedule(
        requestBody: PostCourseScheduleDto,
    ): CancelablePromise<CourseScheduleEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling/courseSchedule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get a Course Schedule
     * @param courseScheduleId
     * @returns CourseScheduleEntity Course Schedule has been returned
     * @throws ApiError
     */
    public schedulingControllerGetCourseSchedule(
        courseScheduleId: string,
    ): CancelablePromise<CourseScheduleEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/courseSchedule/{courseScheduleId}',
            path: {
                'courseScheduleId': courseScheduleId,
            },
            errors: {
                404: `Course Schedule has not been found`,
            },
        });
    }
    /**
     * Delete a Course Schedule
     * @param courseScheduleId
     * @returns CourseScheduleEntity Course Schedule has been deleted
     * @throws ApiError
     */
    public schedulingControllerDeleteCourseSchedule(
        courseScheduleId: string,
    ): CancelablePromise<CourseScheduleEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/scheduling/courseSchedule/{courseScheduleId}',
            path: {
                'courseScheduleId': courseScheduleId,
            },
            errors: {
                404: `Course Schedule has not been found`,
            },
        });
    }
    /**
     * Get all Meeting Schedules of a Course Schedule
     * @param courseScheduleId
     * @returns MeetingScheduleEntity Meeting Schedules have been returned
     * @throws ApiError
     */
    public schedulingControllerGetMeetingSchedulesOfCourseSchedule(
        courseScheduleId: string,
    ): CancelablePromise<Array<MeetingScheduleEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/courseSchedule/{courseScheduleId}/meetingSchedules',
            path: {
                'courseScheduleId': courseScheduleId,
            },
        });
    }
    /**
     * Create a Meeting Schedule
     * @param requestBody
     * @returns MeetingScheduleEntity Meeting Schedule has been created
     * @throws ApiError
     */
    public schedulingControllerCreateMeetingSchedule(
        requestBody: PostMeetingScheduleDto,
    ): CancelablePromise<MeetingScheduleEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling/meetingSchedule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course Schedule has not been found`,
            },
        });
    }
    /**
     * Delete a Meeting Schedule
     * @param meetingScheduleId
     * @returns MeetingScheduleEntity Meeting Schedule has been deleted
     * @throws ApiError
     */
    public schedulingControllerDeleteMeetingSchedule(
        meetingScheduleId: string,
    ): CancelablePromise<MeetingScheduleEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/scheduling/meetingSchedule/{meetingScheduleId}',
            path: {
                'meetingScheduleId': meetingScheduleId,
            },
            errors: {
                404: `Meeting Schedule has not been found`,
            },
        });
    }
    /**
     * Get a Meeting Schedule
     * @param meetingScheduleId
     * @returns MeetingScheduleEntity Meeting Schedule has been returned
     * @throws ApiError
     */
    public schedulingControllerGetMeetingSchedule(
        meetingScheduleId: string,
    ): CancelablePromise<MeetingScheduleEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/meetingSchedule/{meetingScheduleId}',
            path: {
                'meetingScheduleId': meetingScheduleId,
            },
            errors: {
                404: `Meeting Schedule has not been found`,
            },
        });
    }
    /**
     * Get all Schedule Batches
     * @returns ScheduleBatchEntity Schedule Batches have been returned
     * @throws ApiError
     */
    public schedulingControllerGetScheduleBatches(): CancelablePromise<Array<ScheduleBatchEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/scheduleBatch',
        });
    }
    /**
     * Create a Schedule Batch and draft Meetings
     * @param requestBody
     * @returns ScheduleBatchEntity Schedule Batch has been created
     * @throws ApiError
     */
    public schedulingControllerCreateScheduleBatch(
        requestBody: PostScheduleBatchDto,
    ): CancelablePromise<ScheduleBatchEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling/scheduleBatch',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course Schedule has not been found`,
            },
        });
    }
    /**
     * Approve a Schedule Batch
     * @param scheduleBatchId
     * @returns ScheduleBatchEntity Schedule Batch has been approved
     * @throws ApiError
     */
    public schedulingControllerApproveScheduleBatch(
        scheduleBatchId: string,
    ): CancelablePromise<ScheduleBatchEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling/scheduleBatch/{scheduleBatchId}/approve',
            path: {
                'scheduleBatchId': scheduleBatchId,
            },
            errors: {
                404: `Schedule Batch has not been found`,
            },
        });
    }
    /**
     * Reject a Schedule Batch
     * @param scheduleBatchId
     * @returns ScheduleBatchEntity Schedule Batch has been rejected
     * @throws ApiError
     */
    public schedulingControllerRejectScheduleBatch(
        scheduleBatchId: string,
    ): CancelablePromise<ScheduleBatchEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling/scheduleBatch/{scheduleBatchId}/reject',
            path: {
                'scheduleBatchId': scheduleBatchId,
            },
            errors: {
                404: `Schedule Batch has not been found`,
            },
        });
    }
    /**
     * Get a Schedule Batch
     * @param scheduleBatchId
     * @returns ScheduleBatchEntity Schedule Batch has been returned
     * @throws ApiError
     */
    public schedulingControllerGetScheduleBatch(
        scheduleBatchId: string,
    ): CancelablePromise<ScheduleBatchEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/scheduleBatch/{scheduleBatchId}',
            path: {
                'scheduleBatchId': scheduleBatchId,
            },
            errors: {
                404: `Schedule Batch has not been found`,
            },
        });
    }
    /**
     * Get Meetings of a Schedule Batch
     * @param scheduleBatchId
     * @returns MeetingEntity Schedule Batch has been returned
     * @throws ApiError
     */
    public schedulingControllerGetScheduleBatchMeetings(
        scheduleBatchId: string,
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/scheduleBatch/{scheduleBatchId}/meetings',
            path: {
                'scheduleBatchId': scheduleBatchId,
            },
            errors: {
                404: `Schedule Batch has not been found`,
            },
        });
    }
}
